const ENVIRONMENT = 'PROD'
const GATSBY_DEBIE_API_BASE_URI = process.env.GATSBY_DEBIE_API_BASE_URI
const GATSBY_DEBIE_FRONTEND_BASE_URI = process.env.GATSBY_DEBIE_FRONTEND_BASE_URI
const GATSBY_PLAID_API_BASE_URI = process.env.GATSBY_PLAID_API_BASE_URI
const GATSBY_PLAID_ENV = process.env.GATSBY_PLAID_ENV
const GATSBY_STRIPE_PUBLISHABLE_KEY = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
const GATSBY_STRIPE_BUMP_ID = process.env.GATSBY_STRIPE_BUMP_ID
const GATSBY_STRIPE_BOOST_ID = process.env.GATSBY_STRIPE_BOOST_ID
const GATSBY_STRIPE_BUMP_ID_YEARLY = process.env.GATSBY_STRIPE_BUMP_ID_YEARLY
const GATSBY_STRIPE_BOOST_ID_YEARLY = process.env.GATSBY_STRIPE_BOOST_ID_YEARLY
const GATSBY_STRIPE_BUMP_PRICE = process.env.GATSBY_STRIPE_BUMP_PRICE
const GATSBY_STRIPE_BOOST_PRICE = process.env.GATSBY_STRIPE_BOOST_PRICE
const GATSBY_STRIPE_BUMP_PRICE_YEARLY = process.env.GATSBY_STRIPE_BUMP_PRICE_YEARLY
const GATSBY_STRIPE_BOOST_PRICE_YEARLY = process.env.GATSBY_STRIPE_BOOST_PRICE_YEARLY
const GATSBY_STRIPE_BUMP_PRICE_STRIKE = process.env.GATSBY_STRIPE_BUMP_PRICE_STRIKE
const GATSBY_STRIPE_BOOST_PRICE_STRIKE = process.env.GATSBY_STRIPE_BOOST_PRICE_STRIKE
const SEGMENT_KEY = process.env.SEGMENT_KEY || 'D3xeHZiDiBWb24Fv3i0Vvv4OmCbix7nz'


const IS_DEV = ENVIRONMENT === 'DEV'
const IS_STAGING = ENVIRONMENT === 'STAGING'
const IS_PROD = ENVIRONMENT === 'PROD'

const SKIP_SEGMENT_FOR_STAFF = !(IS_DEV || IS_STAGING)
const CONSOLE_LOG_SEGMENT = IS_DEV || IS_STAGING

const settings_obj = ({
	ENVIRONMENT,
	GATSBY_DEBIE_API_BASE_URI,
	GATSBY_DEBIE_FRONTEND_BASE_URI,
	GATSBY_PLAID_API_BASE_URI,
	GATSBY_PLAID_ENV,
	GATSBY_STRIPE_PUBLISHABLE_KEY,
	GATSBY_STRIPE_BUMP_ID,
	GATSBY_STRIPE_BOOST_ID,
	GATSBY_STRIPE_BUMP_PRICE,
	GATSBY_STRIPE_BOOST_PRICE,
	GATSBY_STRIPE_BUMP_ID_YEARLY,
	GATSBY_STRIPE_BOOST_ID_YEARLY,
	GATSBY_STRIPE_BUMP_PRICE_STRIKE,
	GATSBY_STRIPE_BOOST_PRICE_STRIKE,
	GATSBY_STRIPE_BUMP_PRICE_YEARLY,
	GATSBY_STRIPE_BOOST_PRICE_YEARLY,
	IS_DEV,
	IS_STAGING,
	IS_PROD,
	SEGMENT_KEY,
	SKIP_SEGMENT_FOR_STAFF,
	CONSOLE_LOG_SEGMENT
});

export default {
	get: (key) => settings_obj[key] || null
}
